import { BellOutlined } from '@ant-design/icons';
import { GuestCallType, GuestVisitType } from '@kinderlabs-pos/shared-data-type';
import { getRelativeTimeFormat } from '@kinderlabs-pos/shared-util';
import { GuestVisitState, StoreInfoState } from '@kinderlabs-pos/state';
import { useAlert } from '@kinderlabs-pos/ui-components';
import { Button, Stack, Typography } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

export const GuestVisitCallDisplay = ({
	now,
	guestVisit,
	guestVisitCall,
}: {
	now: Dayjs;
	guestVisit: GuestVisitType;
	guestVisitCall?: GuestCallType;
}) => {
	const { posInfoList } = useAtomValue(StoreInfoState.curStoreInfo);
	const curPosId = useAtomValue(StoreInfoState.curStoreAndDevice).deviceId;

	const customAlert = useAlert();
	const callMutation = GuestVisitState.actions.useCallMutation({
		onError: (e) => {
			//@ts-ignore
			customAlert(e?.message ?? '호출실패');
		},
	});

	const isCalledbyThisPos = curPosId === guestVisitCall?.posId;
	const thisPos = useMemo(
		() => posInfoList.find((pos) => pos.id === guestVisitCall?.posId),
		[posInfoList, guestVisitCall]
	);

	const 최근호출이후180초가안지났는지 = now.diff(guestVisitCall?.calledDateTime, 'seconds') < 180;

	return guestVisitCall ? (
		<Stack
			direction={'row'}
			spacing={1}>
			<Button
				disabled={!isCalledbyThisPos}
				color={isCalledbyThisPos ? 'success' : 'error'}
				variant='outlined'
				sx={{ px: 0.5, width: 120, pointerEvents: 'none' }}>
				<Stack>
					<Typography variant={'body2'}>{`POS ${thisPos?.name}`}</Typography>
					<Typography variant={'body1'}>
						{`${getRelativeTimeFormat(now, guestVisitCall.calledDateTime)} 호출`}
					</Typography>
				</Stack>
			</Button>
			<Button
				variant='outlined'
				color={'error'}
				disabled={(!isCalledbyThisPos && 최근호출이후180초가안지났는지) || callMutation.isLoading}
				onClick={() => callMutation.mutate({ guestVisitId: guestVisit.id })}
				startIcon={<BellOutlined />}
				sx={{ width: 96 }}>
				<Typography variant={'subtitle2'}>재호출</Typography>
			</Button>
		</Stack>
	) : (
		<Button
			onClick={() =>
				callMutation.mutate(
					{ guestVisitId: guestVisit.id },
					{
						onError: (e) => {
							console.log('에러에러2');
						},
					}
				)
			}
			variant='outlined'
			disabled={callMutation.isLoading}
			sx={{ width: 96 }}
			startIcon={<BellOutlined />}>
			<Typography variant={'subtitle1'}>{'호출'}</Typography>
		</Button>
	);
};
